import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-neo-button',
    templateUrl: './neo-button.component.html',
    styleUrls: ['./neo-button.component.scss'],
    standalone: true,
})
export class NeoButtonComponent {
    @Input() public loading: boolean = false;
    @Input() public disabled: boolean = false;
    @Input() public class: string = 'neo-filled-button';
    @Input() public text: string = '';

    @Output() public clickEmitter: EventEmitter<any> = new EventEmitter<any>();

    public setText(text: string): void {
        this.text = text;
    }

    public setLoading(loading: boolean): void {
        this.loading = loading;
    }

    public setDisabled(disabled: boolean): void {
        this.disabled = disabled;
    }

    public onClick(): void {
        this.clickEmitter.emit();
    }
}
